import * as types from "./constants"

export const loginAction = data => ({
  type: types.LOGIN,
  payload: data
})

export const loginSuccessAction = response => ({
  type: types.LOGIN_SUCCESS,
  payload: response
})

export const loginErrorAction = response => ({
  type: types.LOGIN_ERROR,
  payload: response
})
