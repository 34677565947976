import { request } from "src/utils/http"

function login(payload) {
  return request
    .post("/login/", payload)
    .then(res => {
      console.log(res)
      return res
    })
    .catch(function (error) {
      console.log(error)
      throw error
    })
}

// function login(payload) {
//   return request
//     .post('/login/', payload)
//     .then((res) => {
//       console.log(res)
//       return res
//     })
//     .catch(function (error) {
//       console.log(error)
//       throw error
//     })
// }

export const authApiService = {
  login
}
