import { combineReducers, createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from "redux-devtools-extension"

import { authenticationReducer } from "./redux/authentication/reducers"
import { sidebarReducer } from "./redux/sidebar/reducers"

import authSaga from "./redux/authentication/sagas"

const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({
  authState: authenticationReducer,
  sidebarState: sidebarReducer
})

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(authSaga)

export default store
