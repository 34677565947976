import { put, call, all, spawn, takeEvery, delay } from "redux-saga/effects"
import { authApiService } from "./services"
import * as actions from "./actions"
import * as types from "./constants"

function* loginWorker(action) {
  try {
    const result = yield call(authApiService.login, action.payload)
    yield put(actions.loginSuccessAction(result.data))
    yield delay(10000)
  } catch (err) {
    console.log("error login", err, err?.response)
    let error = ""

    console.log("re", err?.response?.data?.non_field_errors?.length)

    if (err?.response?.data?.non_field_errors?.length > 0) {
      error = err?.response?.data?.non_field_errors[0]
    } else {
      error = "Something went wrong please try again later"
    }
    console.log("err", error)
    yield put(actions.loginErrorAction({ error: error }))
  }
}

//watchers
function* loginWatcher() {
  yield takeEvery(types.LOGIN, loginWorker)
}

export default function* authRootSaga() {
  const sagas = [loginWatcher]
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}
