import * as types from "./constants"

const initialState = {
  token: null,
  user: null,
  error: null,
  status: "",
  requestInProgress: false
}

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        requestInProgress: true,
        error: null,
        status: ""
      }

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        profile: action.payload.profile,
        status: "success",
        requestInProgress: false,
        error: null
      }

    case types.LOGIN_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
        requestInProgress: false
      }

    default:
      return state
  }
}
