import axios from "axios"
// import store from "src/store"

const cbUrl = "https://round-cake-28035.botics.co/api/v1"
const localUrl = "http://localhost:8000/api/v1"

export const request = axios.create({
  baseURL: cbUrl
})

request.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token")

  if (token) {
    config.headers.Authorization = "Token " + token
  }

  return config
})

request.interceptors.response.use(
  response => {
    return response
  },
  async function (error) {
    console.log("errr", error)
    return Promise.reject(error)
  }
)
