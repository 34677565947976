const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false
}

export const sidebarReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest }
    case "fold":
      return { ...state, ...rest }
    default:
      return state
  }
}
